import React from 'react';
import { Link } from 'gatsby';
import {AiOutlineRight} from 'react-icons/ai'
import 'tachyons';


export default (props) => (
  <div className="w-100 f6 pv3 flex items-center tracked ttu sans-serif justify-center mw6">
    <Link
      to="/"
      className="dark-gray no-underline underline-hover">Home</Link>
    <AiOutlineRight />
    <Link
      to={props.lastPath}
      className="dark-gray no-underline underline-hover">{props.lastName}</Link>
    <AiOutlineRight />
    <span className="mid-gray">{props.currentPage}</span>
  </div>
)
